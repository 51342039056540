/* eslint-disable @typescript-eslint/no-use-before-define */
import { ApolloProvider } from '@apollo/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { GlobalStyles } from '@skand/lego';
import { createBrowserHistory } from 'history';
import { ErrorPage } from 'pages/App/components';
import posthog from 'posthog-js';
import React from 'react';
import ReactDependentScript from 'react-dependent-script';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import graphqlClient from './configureGraphql';
import './index.css';
import { msalConfig } from './msalConfig';
import { App } from './pages';
import * as serviceWorker from './serviceWorker';
import { SplitConfigProvider } from './splitConfig';
import { ignoreConsoleWarn } from './utils/functions';

// Ignore console.warn in production and staging environment
ignoreConsoleWarn(process.env.REACT_APP_ENVIRONMENT);
const history = createBrowserHistory();

// Setup PostHog for production environment
const POSTHOG_API_KEY = process.env.REACT_APP_POSTHOG_API_KEY;
const POSTHOG_API_HOST = process.env.REACT_APP_POSTHOG_API_HOST;
if (process.env.NODE_ENV === 'production' && POSTHOG_API_KEY && POSTHOG_API_HOST) {
  posthog.init(POSTHOG_API_KEY, {
    api_host: POSTHOG_API_HOST
  });
}

window.Intercom('boot', {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'khqdidm3'
});

// Setup Sentry for all environment
Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_ENVIRONMENT,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.2,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    }),
    new Sentry.Replay({
      blockAllMedia: true,
      maskAllText: true,
      networkDetailAllowUrls: [/^https?:\/\/.*platform.skand.io/]
    }),
    new posthog.SentryIntegration(posthog, 'skand-s1', 5454958)
  ]
});

// Initiate
const msalInstance = new PublicClientApplication(msalConfig);

const ExternalScripts = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const googleApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  return (
    <>
      {googleApiKey ? (
        <ReactDependentScript
          scripts={[
            `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&language=en`
          ]}
        >
          {children}
        </ReactDependentScript>
      ) : (
        children
      )}
    </>
  );
};

const Router = () => {
  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <ExternalScripts>
        <SplitConfigProvider>
          <MsalProvider instance={msalInstance}>
            <ApolloProvider client={graphqlClient}>
              <BrowserRouter>
                <GlobalStyles />
                <App />
              </BrowserRouter>
            </ApolloProvider>
          </MsalProvider>
        </SplitConfigProvider>
      </ExternalScripts>
    </ErrorBoundary>
  );
};

ReactDOM.render(<Router />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
