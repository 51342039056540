/* eslint-disable complexity */
import * as Sentry from '@sentry/react';
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import React, { useCallback, useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { theme as themeOriginal } from '@skand/lego';
// eslint-disable-next-line import/no-unresolved
import { Upload } from '@skand/webapp-explore';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { setSentryUser } from 'utils/sentry';
import { colors } from 'utils/styles';
import { Loader } from '../../components';
import { OPERATIONS_OVERALL } from '../../splitConfig';
import { LOCAL_STORAGES, PATHS, PROJECT_ROOT } from '../../utils/constants';
import { allPrivateRoutes, allUnauthorisedRoutes, publicRoutes } from '../../utils/routes';
import {
  ErrorPage,
  PrivateRoute,
  PublicRoute,
  SideNavbar,
  UnauthorisedOnlyRoute,
  WhiteLabelingNavbar
} from './components';
import {
  checkAuthentication,
  checkCurrentRouteInRouteList,
  getSideNavbarStatus,
  initialisePosthog,
  initialiseIntercom,
  useFetchData
} from './utils';
import { useIsBetaFeatureEnabled } from './utils/useIsBetaFeatureEnabled';

const App = () => {
  const { userData, accountDataV2, externalShareData } = useFetchData();
  const { isReady, isTimedout } = useContext(SplitContext);

  const userTreatments = useTreatments([OPERATIONS_OVERALL]);

  const isUserAuthenticated = checkAuthentication();
  const isOperationsVisibleToUser = userTreatments[OPERATIONS_OVERALL]?.treatment === 'on';
  const isWebappBetaEnabled = useIsBetaFeatureEnabled();

  const measurementSystem = userData?.measurementSystem;
  const account = userData?.account;

  const theme = {
    ...themeOriginal,
    primaryColor: isReady && !isWebappBetaEnabled ? colors.orange : colors.primary400,
    bubbleColor: isReady && !isWebappBetaEnabled ? colors.blue500 : colors.primary400
  };

  const GlobalOverrides = createGlobalStyle`
    body {
      background: ${colors.white};
    }
  `;

  useEffect(() => {
    if (!userData?.id || !accountDataV2?.id) return;
    initialisePosthog(userData, accountDataV2);
    initialiseIntercom(userData);
    setSentryUser({ id: userData.id, userEmail: userData.email, accountId: userData.accountId });
    localStorage.setItem(LOCAL_STORAGES.MEASUREMENT_SYSTEM_TYPE, measurementSystem);
  }, [userData, accountDataV2, measurementSystem]);

  const { pathname } = useLocation();
  const hasSideNavbar = getSideNavbarStatus(pathname);
  const pathsWithoutBanner = [`${PATHS.BRANDING}/preview/:logoType`];
  const privateRoutesWithBanner = [
    ...allPrivateRoutes.filter((route) => {
      return !pathsWithoutBanner.includes(route.path);
    })
  ];

  const banner = {
    logo: accountDataV2?.branding?.webappBannerFile?.originalUrl ?? account?.bannerLogoStorageUrl,
    color: accountDataV2?.branding.color
  };
  const hasBannerSetUp =
    accountDataV2?.branding?.isActive &&
    (accountDataV2?.branding?.webappBannerFileId ?? account?.bannerLogoStorageId);
  const hasBanner =
    hasBannerSetUp && checkCurrentRouteInRouteList(pathname, privateRoutesWithBanner);

  const handleRedirect = useCallback(() => {
    if (isUserAuthenticated) {
      return <Redirect to={`${isWebappBetaEnabled ? PROJECT_ROOT : PATHS.ROOT}`} />;
    }
    return <Redirect to={`${PATHS.LOGIN}`} />;
  }, [isWebappBetaEnabled, isUserAuthenticated]);

  const mapPrivateRoutes = (routes) =>
    routes.map((route) => (
      <PrivateRoute
        exact={!route.notExact}
        key={route.pathName}
        path={route.path}
        component={route.component}
        hasWhiteLabelingNavbar={hasBannerSetUp}
        hasSideNavbar={hasSideNavbar}
        betaComponent={route.betaComponent}
      />
    ));

  const mapPublicRoutes = (routes) => {
    return routes.map((route) => (
      <PublicRoute
        exact
        key={route.pathName}
        path={route.path}
        component={route.component}
        betaComponent={route.betaComponent}
      />
    ));
  };

  const mapUnauthorisedRoutes = (routes) => {
    return routes.map((route) => (
      <UnauthorisedOnlyRoute
        exact={!route.notExact}
        key={route.pathName}
        path={route.path}
        component={route.component}
      />
    ));
  };

  return (
    <ThemeProvider theme={theme}>
      {isReady && !isWebappBetaEnabled ? null : <GlobalOverrides />}
      <Sentry.ErrorBoundary fallback={<ErrorPage />}>
        {hasBanner && <WhiteLabelingNavbar whiteLabelingAccount={banner} />}
        <SideNavbar
          externalShareData={externalShareData}
          hasWhiteLabelingNavbar={hasBanner}
          isOperationsVisibleToUser={isOperationsVisibleToUser}
          userData={userData}
          isBetaFeatureEnabled={isWebappBetaEnabled}
        />
        <Loader isFetching={!(isReady || isTimedout)} style={{ position: 'fixed' }}>
          <Switch>
            {mapPrivateRoutes(allPrivateRoutes)}
            {mapPublicRoutes(publicRoutes)}
            {mapUnauthorisedRoutes(allUnauthorisedRoutes)}
            <Redirect from={PATHS.MANAGE} to={PATHS.SITES} />
            <Route component={() => handleRedirect()} />
          </Switch>

          <Upload />
        </Loader>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
