// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react';
import brand from './assets/brand.svg';

import {
  Container,
  Brand,
  Title,
  Description,
  Divider,
  LinkButton,
  LinkButtonPrimary
} from './errorPageStyles';

const ErrorPage = () => {
  return (
    <Container>
      <Brand src={brand} alt="skand" />
      <Title>An error has occurred.</Title>
      <Description>Please try again later or contact support.</Description>
      <LinkButton role="button" href="mailto:support@skand.io" style={{ marginTop: '1.75rem' }}>
        Contact support
      </LinkButton>
      <Divider>or</Divider>
      <LinkButtonPrimary role="button" href="/">
        Go to home page
      </LinkButtonPrimary>
    </Container>
  );
};

ErrorPage.propTypes = {};

export default ErrorPage;
