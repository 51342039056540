import styled from 'styled-components';

import { colors, raleway, roboto } from '../../../../utils/styles';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100dvh;
  margin: 0 auto;
  padding-top: 5rem;
  width: 360px;

  @media (max-width: 400px) {
    width: calc(100% - 2rem);
  }
`;

export const Title = styled.h1`
  color: ${colors.neutral800};
  font-family: ${raleway};
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2rem;
  margin-top: 7rem;
  margin-bottom: 0;
`;

export const Description = styled.p`
  color: ${colors.neutral800};
  font-family: ${raleway};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-top: 0.75rem;
  margin-bottom: 0;
`;

export const Brand = styled.img`
  margin: 0 auto;
  max-width: 100%;
`;

export const Divider = styled.div`
  align-items: center;
  color: ${colors.neutral500};
  display: flex;
  flex-flow: row nowrap;
  font-family: ${roboto};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
  gap: 0.75em;
  margin: 1.75rem 0;

  &::before {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${colors.neutral500};
  }

  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid ${colors.neutral500};
  }
`;

export const LinkButton = styled.a`
  background: transparent;
  border-radius: 0.25rem;
  box-shadow: inset 0px 0px 0px 1px ${colors.primary400};
  color: ${colors.neutral800};
  cursor: pointer;
  display: inline-block;
  font-family: ${roboto};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0.25rem 0;
  text-align: center;
  text-decoration: none;
  width: 100%;

  :hover {
    background: ${colors.primary200};
  }

  :active {
    background: ${colors.primary300};
  }

  :focus {
    box-shadow: inset 0px 0px 0px 1px ${colors.primary400}, 0px 0px 0px 2px ${colors.primary200};
  }
`;

export const LinkButtonPrimary = styled.a`
  background: ${colors.primary400};
  border-radius: 0.25rem;
  color: ${colors.neutral100};
  cursor: pointer;
  display: inline-block;
  font-family: ${roboto};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 0.25rem 0;
  text-align: center;
  text-decoration: none;
  width: 100%;

  :hover {
    background: ${colors.primary500};
  }

  :active {
    background: ${colors.primary500};
    box-shadow: inset 0px 0px 0px 1px ${colors.primary400};
  }

  :focus {
    box-shadow: inset 0px 0px 0px 1px ${colors.primary400}, 0px 0px 0px 2px ${colors.primary200};
  }
`;
