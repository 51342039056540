import { css } from 'styled-components';
import { $tsToFix } from './types';

type ColorsType = {
  black: string;
  black100: string;
  black200: string;
  brown: string;
  white: string;
  charcoalGrey: string;
  lighterCharcoalGrey: string;
  transparent: string;
  green100: string;
  green200: string;
  greyCharcoal: string;
  greyLight: string;
  greyShark: string;
  grey900: string;
  grey800: string;
  grey700: string;
  grey600: string;
  grey500: string;
  grey400: string;
  grey300: string;
  greenWumara: string;
  greenSpectrum: string;
  greyHeliguy: string;
  error: string;
  error400: string;
  success: string;
  red: string;
  red200: string;
  red300: string;
  orange: string;
  blue100: string;
  blue200: string;
  blue300: string;
  blue400: string;
  blue500: string;
  blue600: string;
  blue700: string;
  blue800: string;
  blue900: string;
  blue1000: string;
  blue1100: string;
  blueAgonis: string;
  blueBennettAndBennett: string;
  blueCampeyn: string;
  blueRealServe: string;
  blueSensorem: string;
  blueDTS: string;
  purple: string;
  beige: string;
  dropdownBackground: string;
  inputBackground: string;
  primary200: string;
  primary300: string;
  primary400: string;
  primary500: string;
  neutral100: string;
  neutral500: string;
  neutral800: string;
};

// Colors
export const colors: ColorsType = {
  black: '#000000',
  black100: '#1E1D1C',
  black200: '#333333',
  brown: '#45453A',
  white: '#FFFFFF',
  charcoalGrey: '#2b2e38',
  lighterCharcoalGrey: '#373843',
  transparent: 'transparent',
  green100: '#77AD1C',
  green200: '#006A4C',
  greyCharcoal: '#2B2E38',
  greyLight: '#EEEEEE',
  greyShark: '#1D2024',
  grey900: '#25272F',
  grey800: '#191C21',
  grey700: '#2F3239',
  grey600: '#3C3F47',
  grey500: '#4C5261',
  grey400: '#5E676E',
  grey300: '#909194',
  greenWumara: '#3A854A',
  greenSpectrum: '#5ffede',
  greyHeliguy: '#3C3C3C',
  error: '#D0021B',
  error400: '#F8586C',
  success: '#81E98D',
  red: '#FA3926',
  red200: '#E63D3E',
  red300: '#E73C3E',
  orange: '#F47C31',
  blue100: '#edffff ',
  blue200: '#a2d9e6',
  blue300: '#2E4D4B',
  blue400: '#558D89',
  blue500: '#7BCDC7',
  blue600: '#4a85b8',
  blue700: '#00009E',
  blue800: '#4272B8',
  blue900: '#123A5A',
  blue1000: '#0000A5',
  blue1100: '#2F68B2',
  blueCampeyn: '#242f71',
  blueAgonis: '#293991',
  blueBennettAndBennett: '#009bda',
  blueSensorem: '#0090d4',
  blueRealServe: '#0b7cc2',
  blueDTS: '#00659e',
  purple: '#B64FE9',
  beige: '#E1E0D9',
  dropdownBackground: '#565D67',
  inputBackground: '#373843',

  primary200: '#B2C6FF',
  primary300: '#809FFF',
  primary400: '#0040FF',
  primary500: '#002699',
  neutral100: '#FFFFFF',
  neutral500: '#8A858E',
  neutral800: '#333234'
};

export const gradients = {
  grey: `radial-gradient(
      circle at top left,
      #484F60 0%,
      #20232A 42%
    )`
};

export const baseFontColor = colors.white;
export const baseFontSize = '16px';
export const baseFontFamily = 'Heebo, sans-serif';
export const raleway = 'Raleway, sans-serif';
export const roboto = 'Roboto, sans-serif';

export const spacing = {
  xxSmall: '4px',
  xSmall: '8px',
  small: '16px',
  base: '24px',
  medium: '32px',
  midLarge: '48px',
  large: '64px',
  xLarge: '100px'
};

export const sizes = {
  whiteLabelingColorBar: '10px',
  whiteLabelingContentBar: '82px'
};

export const grid = {
  columns: 12,
  gutter: 8
};

type FontSizeType = {
  xLarge: string;
  large: string;
  medium: string;
  base: string;
  small: string;
  xSmall: string;
};

export const fontSize: FontSizeType = {
  xLarge: '2rem',
  large: '1.5rem',
  medium: '1.25rem',
  base: '1rem',
  small: '0.88rem',
  xSmall: '0.75rem'
};

export const fontWeight = {
  bold: 700,
  medium: 500,
  normal: 400
};

export const global = {
  radius: '4px',
  transitionSpeed: '300ms',
  easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  shadow: '0 2px 5px 0 rgba(0,0,0,0.26)',
  shadowLarge: '2px 2px 32px 0 rgba(0,0,0,0.5)',
  shadowDropdown: '0 8px 25px 0 rgba(0,0,0,0.25)',
  tapHighlightColor: 'rgba(255, 255, 255, 0);'
};

export const layer = {
  tooltipOnTop: 10000,
  modal: 9000,
  nav: 800
};

export const bp = {
  small: '600px',
  medium: '1000px',
  large: '1200px',
  xLarge: '1400px',
  xxLarge: '1600px',
  xxxLarge: '2000px',
  xxxxLarge: '2300px'
};

export const componentWidth = {
  nav: {
    default: '100%',
    small: '100%',
    medium: '62px',
    large: '62px',
    xLarge: '62px',
    xxLarge: '62px',
    xxxLarge: '62px',
    xxxxLarge: '62px'
  },
  exploreNav: {
    default: '100%',
    small: '100%',
    medium: '62px',
    large: '62px',
    xLarge: '62px',
    xxLarge: '62px',
    xxxLarge: '62px',
    xxxxLarge: '62px'
  },
  sidebar: {
    default: '100%',
    small: '100%',
    medium: '256px',
    large: '256px',
    xLarge: '256px',
    xxLarge: '350px',
    xxxLarge: '450px',
    xxxxLarge: '450px'
  },
  defectBar: {
    default: '100%',
    small: '100%',
    medium: '256px',
    large: '256px',
    xLarge: '256px',
    xxLarge: '350px',
    xxxLarge: '450px',
    xxxxLarge: '450px'
  },
  forms: 470,
  loginWidth: 500
};

export const componentHeight = {
  nav: {
    default: '62px',
    small: '62px',
    medium: '100%',
    large: '100%',
    xLarge: '100%',
    xxLarge: '100%',
    xxxLarge: '100%',
    xxxxLarge: '100%'
  },
  map: '530px',
  mapInfoBox: '261px'
};

type BreakpointType = {
  small: $tsToFix;
  medium: $tsToFix;
  large: $tsToFix;
  xLarge: $tsToFix;
  xxLarge: $tsToFix;
  xxxLarge: $tsToFix;
  xxxxLarge: $tsToFix;
};

export const breakpoint: BreakpointType = {
  small: (...args: [$tsToFix]) =>
    css`
      @media (min-width: ${bp.small}) {
        ${css(...args)};
      }
    `,
  medium: (...args: [$tsToFix]) =>
    css`
      @media (min-width: ${bp.medium}) {
        ${css(...args)};
      }
    `,
  large: (...args: [$tsToFix]) =>
    css`
      @media (min-width: ${bp.large}) {
        ${css(...args)};
      }
    `,
  xLarge: (...args: [$tsToFix]) =>
    css`
      @media (min-width: ${bp.xLarge}) {
        ${css(...args)};
      }
    `,
  xxLarge: (...args: [$tsToFix]) =>
    css`
      @media (min-width: ${bp.xxLarge}) {
        ${css(...args)};
      }
    `,
  xxxLarge: (...args: [$tsToFix]) =>
    css`
      @media (min-width: ${bp.xxxLarge}) {
        ${css(...args)};
      }
    `,
  xxxxLarge: (...args: [$tsToFix]) =>
    css`
      @media (min-width: ${bp.xxxxLarge}) {
        ${css(...args)};
      }
    `
};
